<template>
  <div class="row">
    <div class="col-12">
        <div class="card card-body">
            <img :src="require('@/assets/images/loading.svg')" v-if="loading" style="width: 50px; height; 50px;" alt="">
            <div class="col-12 table-responsive">
                <div class="form-group col-12 col-lg-6 g">
                  <label for="">اختيار محصل معين</label>
                  <select class="form-control" name="" id="" v-model="selected_cash">
                    <option :value="false">-- اختر --</option>
                    <option v-for="cash in cashsarr" :value="cash._id" :key="cash._id">{{ cash.name }} ({{ countt(cash._id) }})</option>
                  </select>
                </div>
                <table class="table table-hover table-sm table-bordered">
                    <thead>
                        <th>
                           <b-form-checkbox
                            @change="!all ? selected = [] : selected = orders.map(function(x){return x._id})"
                            v-model="all"
                            :value="true"
                            >
                           تحديد الكل
                        </b-form-checkbox>
                        </th>
                        <th>
                            المحصل
                        </th>
                        <th>
                            الاسم
                        </th>
                        <th>
                            الهاتف
                        </th>
                        <th>
                            الوكيل
                        </th>
                        <th>
                            الفندق
                        </th>
                        <th>
                            الشركة
                        </th>
                        <th>
                            تاريخ الدخول
                        </th>
                        <th>
                            عدد الايام
                        </th>
                        <th>
                            تاريخ التحصيل
                        </th>
                        <th>
                            الاجمالي
                        </th>
                    </thead>
                    <tbody>
                        <template v-for="order in orders">
                        <tr :key="order._id" v-if="order.cash_id == selected_cash">
                            <td>
                                <b-form-checkbox
                                    v-model="selected"
                                    :value="order._id"
                                    >
                                    {{ order.id }}
                                </b-form-checkbox>
                            </td>
                            <td>
                                {{ order.cash.name }}
                            </td>
                            <td>
                                {{ order.name }}
                            </td>
                            <td>
                                {{ order.phone }}
                            </td>
                            <td>
                                {{ order.reseller.name }}
                            </td>
                            <td>
                                {{ order.hotel.title }}
                            </td>
                            <td>
                                {{ order.company.title }}
                            </td>
                            <td>
                                {{ order.days[0] }}
                            </td>
                            <td>
                                {{ order.days.length - 1 }}
                            </td>
                            <td>
                                {{ order.cash_done_date }}
                                <span v-if="order.company_calc">
                                    <br>
                                    <i class="fa fa-check-square text-success"></i>
                                    {{ order.cash_notes }}
                                </span>
                            </td>
                            <td>
                                {{ order.total_reseller + order.extra }} ريال
                            </td>
                        </tr></template>
                    </tbody>
                </table>
            </div>
            <div class="col-12 text-center g" v-if="selected.length">
                <button class="btn btn-primary" @click="getCashs()" v-b-modal.modal-1>
                    <i class="fa fa-check"></i>
                    تمت محاسبة المحصل
                </button>
            </div>
        </div>
    </div>
    <b-modal id="modal-1" size="lg" title="تعيين الطلبات انها تمت المحاسبة عليها من المحصل" hide-footer>
        <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered">
                <thead>
                    <th>
                        المحصل
                    </th>
                    <th>
                        اجمالي الطلبات
                    </th>
                </thead>
                <tbody>
                    <tr v-for="cash in cashs" :key="cash.name">
                        <td>{{ cash.name }}</td>
                        <td>{{ cash.total }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="alert alert-danger text-center g">
            هذه الخطوة لا يمكن التراجع عنها
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-primary" @click="done()">
                <i class="fa fa-check"></i>
                تمت محاسبة المحصل
            </button>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { VBModal, BModal, BFormCheckbox } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    components: {
        BModal,BFormCheckbox,
    },
    data(){
        return {
            jwt: JSON.parse(localStorage.getItem("user")).jwt,
            orders: [],
            loading: false,
            selected: [],
            all: false,
            cashs: [],
            selected_cash: false,
            cashsarr: []
        }
    },
    beforeDestroy(){
        clearInterval(this.cc)
    },
    created(){
        var g = this;
        this.getOrders();
    },
    methods: {
        countt(id){
            var g = this;
            var t = 0;
            g.orders.forEach(function(a){
                if(a.cash_id == id){
                    t++
                }
            })
            return t;
        },
        getOrders(){
            var g = this;
            g.loading = true;
            $.post(api + '/admin/orders/cash-orders', {
                jwt: this.jwt
            }).then(function(r){
                g.loading = false;
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.orders = r.response
                }
            }).fail(function(){
                g.loading = false;
                alert("حدث مشكلة في الاتصال")
            })
            $.post(api + '/admin/cashs/all', {
                jwt: this.jwt
            }).then(function(r){
                g.loading = false;
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.cashsarr = r.response
                }
            }).fail(function(){
                g.loading = false;
                alert("حدث مشكلة في الاتصال")
            })
        },
        getCashs(){
            var g = this;
            var arr = {};
            g.orders.forEach(function(a){
                if(g.selected.includes(a._id)){
                    if(!arr[a.cash_id]){
                        arr[a.cash_id] = {
                            total: 0
                        }
                    }
                    arr[a.cash_id].name = a.cash.name;
                    arr[a.cash_id].total = arr[a.cash_id].total + a.total_reseller + a.extra;
                }
            })
            var arr2 = [];
            for (const [key, value] of Object.entries(arr)) {
                arr2.push(value)
            }
            g.cashs = arr2
        },
        done(){
            var g = this;
            g.loading = true;
            $.post(api + '/admin/orders/cash-done', {
                jwt: this.jwt,
                ids: this.selected
            }).then(function(r){
                g.loading = false;
                if(r.status != 100){
                    alert(r.response)
                }else{
                    $("#modal-1___BV_modal_header_ > button").click()
                    g.getOrders()
                }
            }).fail(function(){
                g.loading = false;
                alert("حدث مشكلة في الاتصال")
            })
        }
    }
}
</script>

<style>

</style>